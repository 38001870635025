/**
 * @file It contains vuex channels module mutations to mutate the state variables.
 */
export default {
  setLoadingChannels: (state, loadingChannels) => {
    state.loadingChannels = loadingChannels
  },
  setChannels: (state, channels) => {
    state.channels = channels
  },
  updateChannels: (state, channels) => {
    for (const channel of channels) {
      const index = state.channels.findIndex(stateChannel => stateChannel?.id === channel.id)
      if (index >= 0) {
        state.channels.splice(index, 1, { ...state.channels[index], ...channel })
      } else {
        state.channels.push(channel)
      }
    }
  },
  setFormTemplatesForChannel: (state, { id, formTemplateId }) => {
    state.formTemplatesOfChannels = Object.assign({}, state.formTemplatesOfChannels, {
      [id]: formTemplateId
    })
  },
  resetFormTemplatesOfChannels: state => {
    state.formsOfChannels = new Object()
  },
  setLogoForChannel: (state, { id, logo }) => {
    state.logoOfChannels = Object.assign({}, state.logoOfChannels, {
      [id]: logo
    })
  },
  resetLogoOfChannels: state => {
    state.logoOfChannels = new Object()
  },
  setLoadingFormTemplatesForChannel: (state, loadingFormTemplatesForChannel) => {
    state.loadingFormTemplatesForChannel = loadingFormTemplatesForChannel
  },
  setTranslationConfigurationsForChannel: (state, { id, translationConfigurationsOfChannels }) => {
    state.translationConfigurationsOfChannels = Object.assign({}, state.translationConfigurationsOfChannels, {
      [id]: translationConfigurationsOfChannels
    })
  },
  setLoadingTranslationConfigurations: (state, loadingTranslationConfigurations) => {
    state.loadingTranslationConfigurations = loadingTranslationConfigurations
  },
  setOrganisationCode: (state, organisationCode) => {
    state.organisationCode = organisationCode
  },
  setOrganisationCodeVerified: (state, organisationCodeVerified) => {
    state.organisationCodeVerified = organisationCodeVerified
  },
  setVerifyingOrganisationCode: (state, verifyingOrganisationCode) => {
    state.verifyingOrganisationCode = verifyingOrganisationCode
  },
  setLoadingChannelLogo: (state, loadingChannelLogo) => {
    state.loadingChannelLogo = loadingChannelLogo
  },
  setLoadingPrivacyPolicyTranslations: (state, loadingPrivacyPolicyTranslations) => {
    state.loadingPrivacyPolicyTranslations = loadingPrivacyPolicyTranslations
  },
  setPrivacyPolicyTranslations: (state, privacyPolicyTranslations) => {
    state.privacyPolicyTranslations = privacyPolicyTranslations
  },
  setShowPrivacyStatement: (state, showPrivacyStatement) => {
    state.showPrivacyStatement = showPrivacyStatement
  },
  setLoadingPhoneNumbers: (state, loadingPhoneNumbers) => {
    state.loadingPhoneNumbers = loadingPhoneNumbers
  },
  setPhoneNumbers: (state, phoneNumbers) => {
    state.phoneNumbers = phoneNumbers
  }
}