import { mapActions, mapGetters } from "vuex"
import { CHANNEL_CONTENT } from "@/constants"
export default {
  name: "Home",
  data() {
    return {
      isTriageDialogOpen           : false,
      isWebAndPhoneReportDialogOpen: false
    }
  },
  computed: {
    ...mapGetters({
      channels           : "channels/channels",
      logoOfChannels     : "channels/logoOfChannels",
      channelTranslations: "channels/channelTranslations",
      languages          : "languages/languages"
    }),
    channel() {
      const channel = this.channels.find(channel => channel.name === this.$route.params.channel)
      return channel
    },
    isShowPhoneNumbersEnabled() {
      return this.channel.showPhoneNumbers
    },
    channelTranslations() {
      return this.channel.channelTranslations
    },
    languageMap() {
      const languageMap = new Map()
      for (const language of this.languages) {
        languageMap[language.id] = language
      }
      return languageMap
    },
    description() {
      const channelTranslation = this.channelTranslations.find( translation =>
        translation.content === CHANNEL_CONTENT.DESCRIPTION
        && this.$i18n.locale === this.languageMap[translation.languageId].shortName
      )
      return channelTranslation?.translation ?? this.channel.description
    },
    channelSpeakUpDescription() {
      const channelTranslation = this.channelTranslations.find( translation =>
        translation.content === CHANNEL_CONTENT.SPEAKUP_DESCRIPTION
        && this.$i18n.locale === this.languageMap[translation.languageId].shortName
      )
      return channelTranslation?.translation ?? this.channel.speakupDescription
    },
    channelAlternativeDescription() {
      const channelTranslation = this.channelTranslations.find( translation =>
        translation.content === CHANNEL_CONTENT.ALTERNATIVE_DESCRIPTION
        && this.$i18n.locale === this.languageMap[translation.languageId].shortName
      )
      return channelTranslation?.translation ?? this.channel.alternativeDescription
    },
    isTriageEnabled() {
      return this.channel.triageUrl !== null && this.channel.speakupDescription !== null
        && this.channel.alternativeDescription !== null
    },
    logoOfChannel() {
      return this.logoOfChannels[this.channel.id]
    },
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    ...mapActions({
      notify: "shared/notify"
    }),
    redirectToCustomUrl() {
      window.open(this.channel.triageUrl, "_blank")
    },
    handleClickOutside() {
      this.isTriageDialogOpen = false
    },
    handleClickOnNewReportButton() {
      if(!this.isShowPhoneNumbersEnabled){
        this.$router.push({
          name  : "languages",
          params: {
            locale : this.$route.params.locale,
            channel: this.$route.params.channel
          }
        })
      } else{
        this.isWebAndPhoneReportDialogOpen = true
      }
    },
    handlePhoneReport() {
      this.$router.push({
        name  : "phone-numbers",
        params: {
          locale : this.$route.params.locale,
          channel: this.$route.params.channel
        }
      })
    }
  },
  watch: {
    channel: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && !newValue.enabled) {
          this.notify({
            type: "error",
            text: "92"
          })
        }
      }
    }
  }
}
