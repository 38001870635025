/**
 * @file It contains all the vuex channels module state variables.
 */
export default {
  loadingChannels                    : false,
  channels                           : new Array(),
  formTemplatesOfChannels            : new Object(),
  loadingFormTemplates               : false,
  translationConfigurationsOfChannels: new Object(),
  loadingTranslationConfigurations   : false,
  organisationCode                   : undefined,
  organisationCodeVerified           : undefined,
  verifyingOrganisationCode          : undefined,
  loadingChannelLogo                 : false,
  logoOfChannels                     : new Object(),
  privacyPolicyTranslations          : new Array(),
  loadingPrivacyPolicyTranslations   : false,
  showPrivacyStatement               : false,
  loadingPhoneNumbers                : undefined,
  phoneNumbers                       : new Array()
}