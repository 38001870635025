/**
 * @file It sets up routing for this application using vue-router.
 * It contains all the routes for this application.
 */
import Vue from "vue"
import Router from "vue-router"
import routeCheck from "@/middleware/routeCheck"
Vue.use(Router)

const router = new Router({
  mode  : "history",
  base  : process.env.BASE_URL,
  routes: [{
    path    : "*",
    redirect: "/not-found"
  }, {
    path: "/not-found",
    name: "not-found",
    meta: {
      layout: "Blank"
    },
    component: () => import(/* webpackChunkName: "not-found" */ "@/views/not-found")
  }, {
    path: "/:locale/:channel/home",
    name: "home",
    meta: {
      layout  : "Default",
      logo    : true,
      privacy : true,
      language: true
    },
    component: () => import(/* webpackChunkName: "home" */ "@/views/home")
  }, {
    path    : "/:locale/:channel",
    name    : "default-home",
    redirect: "/:locale/:channel/home"
  }, {
    path: "/:channel",
    name: "default-locale"
  }, {
    path: "/:locale/:channel/login",
    name: "login",
    meta: {
      layout  : "Default",
      logo    : false,
      privacy : false,
      language: false,
      return  : "home"
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/login")
  }, {
    path: "/:locale/:channel/languages",
    name: "languages",
    meta: {
      layout : "Default",
      title  : "5",
      privacy: true,
      logo   : false,
      return : "home",
      logout : true
    },
    component: () => import(/* webpackChunkName: "languages" */ "@/views/languages")
  }, {
    path: "/:locale/:channel/consent",
    name: "consent",
    meta: {
      layout : "Default",
      title  : "170",
      privacy: true,
      logo   : false,
      return : "home",
      logout : true
    },
    component: () => import(/* webpackChunkName: "consent" */ "@/views/consent")
  }, {
    path: "/:locale/:channel/reports",
    name: "reports"
  }, {
    path: "/:locale/:channel/reports/:reportId",
    name: "report",
    meta: {
      layout  : "Default",
      logo    : false,
      privacy : true,
      language: false,
      title   : "24",
      logout  : true
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/report")
  }, {
    path: "/:locale/:channel/organisation-code",
    name: "organisation-code",
    meta: {
      layout : "Default",
      privacy: true,
      logo   : false,
      return : "languages",
      title  : "14",
      logout : true
    },
    component: () => import(/* webpackChunkName: "organisation-code" */ "@/views/organisation-code")
  }, {
    path: "/:locale/:channel/reports/:reportId/intake",
    name: "intake",
    meta: {
      protected: true,
      layout   : "Default",
      privacy  : false,
      logo     : false,
      title    : "41",
      logout   : true
    },
    component: () => import(/* webpackChunkName: "intake" */ "@/views/intake")
  }, {
    path: "/:locale/:channel/reports/:reportId/messages/:messageId",
    name: "message",
    meta: {
      protected: true,
      layout   : "Default",
      privacy  : true,
      logo     : false,
      title    : "42",
      logout   : true,
      return   : true
    },
    component: () => import(/* webpackChunkName: "message" */ "@/views/message")
  }, {
    path: "/:locale/:channel/logout",
    name: "logout",
    meta: {
      layout  : "Default",
      logo    : true,
      privacy : true,
      language: true
    },
    component: () => import(/* webpackChunkName: "logout" */ "@/views/logout")
  }, {
    path: "/:locale/:channel/reports/:reportId/acknowledgement",
    name: "acknowledgement",
    meta: {
      layout   : "Default",
      privacy  : false,
      logo     : false,
      logout   : true,
      protected: true
    },
    component: () => import(/* webpackChunkName: "acknowledgement" */ "@/views/acknowledgement")
  }, {
    path: "/:locale/:channel/reports/:reportId/verify-email",
    name: "verify-email",
    meta: {
      layout   : "Default",
      privacy  : false,
      logo     : false,
      logout   : true,
      protected: true
    },
    component: () => import(/* webpackChunkName: "verify-email" */ "@/views/verify-email")
  }, {
    path: "/:locale/:channel/reports/:reportId/email-verified",
    name: "email-verified",
    meta: {
      layout   : "Default",
      privacy  : false,
      logo     : false,
      logout   : true,
      protected: true
    },
    component: () => import(/* webpackChunkName: "email-verified" */ "@/views/email-verified")
  }, {
    path: "/:locale/:channel/reports/:reportId/messages",
    name: "messages",
    meta: {
      protected: true,
      layout   : "Default",
      privacy  : true,
      logo     : false,
      title    : "96",
      logout   : true
    },
    component: () => import(/* webpackChunkName: "messages" */ "@/views/messages")
  }, {
    path: "/:locale/:channel/phone-numbers",
    name: "phone-numbers",
    meta: {
      layout : "Default",
      privacy: true,
      logout : true,
      return : "home",
      title  : "202"
    },
    component: () => import(/* webpackChunkName: "phone-numbers" */ "@/views/phone-numbers")
  }]
})

router.beforeEach(routeCheck)
export default router