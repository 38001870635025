/**
 * @file It exports a method that should be executed before changing routes.
 */
import store from "@/plugins/vuex"
import { DEFAULT_LANGUAGE, CONFIGURATIONS } from "@/constants"

export default async (to, from, next) => {
  let newRoute
  newRoute = await beforeFirstLoad(to, from)
  if (!newRoute) {
    if (to.meta?.protected) {
      newRoute = beforeProtectedRoute(to)
      if (!newRoute) {
        switch(to.name) {
          case "intake": {
            newRoute = await beforeIntake(to, from)
            break
          }
          case "messages": {
            newRoute = await beforeMessages(to, from)
            break
          }
          case "acknowledgement": {
            newRoute = await beforeAcknowledgement(to, from)
            break
          }
          case "verify-email": {
            newRoute = await beforeVerifyEmail(to, from)
            break
          }
          case "email-verified": {
            newRoute = await beforeEmailVerified(to, from)
            break
          }
          case "message": {
            newRoute = await beforeMessage(to, from)
            break
          }
        }
      }
    } else {
      switch(to.name) {
        case "default-locale": {
          newRoute = await beforeDefaultLocale(to)
          break
        }
        case "not-found": {
          newRoute = await beforeNotFound()
          break
        }
        case "languages": {
          newRoute = await beforeLanguages(to, from)
          break
        }
        case "consent": {
          newRoute = await beforeConsent(to)
          break
        }
        case "intake": {
          newRoute = await beforeIntake(to, from)
          break
        }
        case "organisation-code": {
          newRoute = await beforeOrganisationCode(to, from)
          break
        }
        case "login": {
          newRoute = await beforeLogin(to, from)
          break
        }
        case "reports": {
          newRoute = await beforeReports(to, from)
          break
        }
        case "report": {
          newRoute = await beforeReport(to, from)
          break
        }
        case "home": {
          newRoute = await beforeHome(to, from)
          break
        }
        case "phone-numbers": {
          newRoute = await beforePhoneNumbers(to)
          break
        }
      }
    }
  }

  if (!newRoute) {
    if (typeof to.meta.breadcrumbs === "function") {
      store.commit("shared/setBreadcrumbs", to.meta.breadcrumbs(to))
    } else if (to.meta.breadcrumbs) {
      store.commit("shared/setBreadcrumbs", to.meta.breadcrumbs)
    } else {
      store.commit("shared/setBreadcrumbs", undefined)
    }
    store.commit("configurations/setSplashScreen", false)
    next()
  } else {
    next(newRoute)
  }
}

const beforeDefaultLocale = to => {
  const languages = store.getters["languages/languages"]
  let language

  const browserLanguageCode                  = navigator.language.split("-")[0]
  const matchingBrowserLanguageCodeLanguages = []
  let matchingBrowserLanguageCode
  let matchingBrowserLanguage
  let defaultLanguage

  for (const language of languages) {
    if (language.shortName === DEFAULT_LANGUAGE.shortName) {
      defaultLanguage = language
    }
    if (navigator.language.toLowerCase() === language.shortName.toLowerCase()) {
      matchingBrowserLanguage = language
    } else if (language.shortName === browserLanguageCode) {
      matchingBrowserLanguageCode = language
    } else if (language.shortName.startsWith(browserLanguageCode)) {
      matchingBrowserLanguageCodeLanguages.push(language)
    }
  }

  if (matchingBrowserLanguage) {
    language = matchingBrowserLanguage
  } else if (matchingBrowserLanguageCode) {
    language = matchingBrowserLanguageCode
  } else if (matchingBrowserLanguageCodeLanguages.length) {
    language = matchingBrowserLanguageCodeLanguages[0]
  } else if (defaultLanguage) {
    language = defaultLanguage
  } else {
    language = languages[0]
  }

  return {
    name  : "default-home",
    params: {
      channel: to.params.channel,
      locale : language.shortName
    }
  }
}

/**
  * This method will be called before navigating to any protected page.
  * @param {*} to route to be navigated to.
  * @param {*} from route from where navigation was triggered.
  * @param {*} next method to change navigation.
  */
const beforeProtectedRoute = to => {
  if (!store.getters["auth/isLoggedIn"]) {
    return {
      name  : "logout",
      params: {
        locale : to.params.locale || "en-GB",
        channel: to.params.channel
      }
    }
  }
}

/**
  * This method will be called to extract clientName.
  */
const extractClientName = () => {
  if (window.location.host.includes(9010)) {
    return process.env.VUE_APP_CLIENT_NAME
  } else {
    return window.location.host.split(".")[0]
  }
}

/**
  * This method will be called before navigating to login page.
  * @param {*} to route to be navigated to.
  * @param {*} from route from where navigation was triggered.
  */
const beforeFirstLoad = async (to, from) => {
  if (!from.name && !["not-found", "logout"].includes(to.name)) {
    store.commit("configurations/setSplashScreen", true)
    const isLoggedIn = store.getters["auth/isLoggedIn"]
    if (!isLoggedIn) {
      store.dispatch("auth/logout")
    }
    const report                  = store.getters["auth/report"]
    let clientName                = store.getters["auth/clientName"]
    let reporterUserPoolId        = store.getters["auth/reporterUserPoolId"]
    let reporterUserPoolClientId  = store.getters["auth/reporterUserPoolClientId"]
    let askOrganisationCodeForWeb = store.getters["auth/askOrganisationCodeForWeb"]
    let channel
    const setGlobalConfigurations = async () => {
      await store.dispatch("configurations/loadConfigurations")
      const configurations = store.getters["configurations/configurations"]

      reporterUserPoolId = undefined
      store.commit("auth/setReporterUserPoolId", reporterUserPoolId)
      reporterUserPoolClientId = undefined
      store.commit("auth/setReporterUserPoolClientId", reporterUserPoolClientId)

      if (configurations.length) {
        for(const configuration of configurations) {
          if (configuration.key === CONFIGURATIONS.REPORTER_USER_POOL_ID) {
            reporterUserPoolId = configuration.value
            store.commit("auth/setReporterUserPoolId", reporterUserPoolId)
          }
          if (configuration.key === CONFIGURATIONS.REPORTER_USER_POOL_CLIENT_ID) {
            reporterUserPoolClientId = configuration.value
            store.commit("auth/setReporterUserPoolClientId", reporterUserPoolClientId)
          }
          if (configuration.key === CONFIGURATIONS.ASK_ORGANISATION_CODE_FOR_WEB) {
            askOrganisationCodeForWeb = configuration.value === "true"
            store.commit("auth/setSkipOrganisationCodeForWeb", askOrganisationCodeForWeb)
          }
        }
      }
    }

    const setChannel = async name => {
      const channels = store.getters["channels/channels"]
      channel        = channels.find(
        channelInStore => channelInStore.name = name
      )
      if (!channel) {
        await store.dispatch("channels/loadChannels", {
          name             : to.params.channel,
          fetchTranslations: true
        })
        const channels = store.getters["channels/channels"]
        channel        = channels.find(
          channelInStore => channelInStore.name = name
        )

        if (channel.privacyPolicy && !!channel.privacyPolicyText) {
          store.dispatch("channels/loadPrivacyPolicyTranslations", { id: channel.id, actual: true })
        }
      }
    }
    const channelName = to.params.channel

    if (!clientName) {
      clientName = extractClientName()
      store.commit("auth/setClientName", clientName)
    }

    if (clientName && channelName) {
      await store.dispatch("auth/loadRegion")
      const setGlobalConfigurationsPromise = setGlobalConfigurations()
      const setChannelPromise              = setChannel(channelName)
      await Promise.all([setGlobalConfigurationsPromise, setChannelPromise])
    }

    if (!(clientName && channelName && channel
      && askOrganisationCodeForWeb !== undefined
      && reporterUserPoolId && reporterUserPoolClientId
    )) {
      store.commit("configurations/setSplashScreen", false)
      return {
        name: "not-found"
      }
    } else {
      if (report && report.channelId !== channel?.id) {
        store.commit("configurations/setSplashScreen", false)
        return {
          name  : "logout",
          params: {
            locale : to.params.locale || "en-GB",
            channel: to.params.channel
          }
        }
      }
      const loadLanguagesPromise = store.dispatch("languages/loadLanguages")
      store.dispatch("channels/loadTranslationConfigurations", channel.id)
      await loadLanguagesPromise
      if (report?.languageId) {
        const languages = store.getters["languages/languages"]
        for (const language of languages) {
          if (language.id === report.languageId) {
            store.commit("auth/setReporterLanguage", language)
          }
        }
      }
    }
  }
}

const beforeHome = to => {
  const isLoggedIn = store.getters["auth/isLoggedIn"]
  if (isLoggedIn) {
    return {
      name  : "reports",
      params: {
        locale : to.params.locale || "en-GB",
        channel: to.params.channel
      }
    }
  } else {
    const channels = store.getters["channels/channels"]
    const channel  = channels.find(
      channelInStore => channelInStore.name = to.params.channel
    )
    if(channel?.logo) {
      store.dispatch("channels/loadChannelLogo", channel)
    }
  }
}

const beforePhoneNumbers = to => {
  const channels = store.getters["channels/channels"]
  const channel  = channels.find(
    channelInStore => channelInStore.name = to.params.channel
  )
  store.dispatch("channels/loadPhoneNumbers", {
    id              : channel.id,
    organisationCode: channel.organisationCode
  })
}
/**
  * This method will be called before navigating to login page.
  */
const beforeNotFound = async () => {
  store.commit("auth/setClientName", undefined)
  store.commit("auth/setReporterUserPoolId", undefined)
  store.commit("auth/setReporterUserPoolClientId", undefined)
  store.commit("auth/setSkipOrganisationCodeForWeb", undefined)
}

/**
  * This method will be called before navigating to login page.
  */
const beforeLogin = async to => {
  const isLoggedIn = store.getters["auth/isLoggedIn"]
  if (isLoggedIn) {
    return {
      name  : "reports",
      params: {
        locale : to.params.locale || "en-GB",
        channel: to.params.channel
      }
    }
  }
}

const beforeOrganisationCode = async to => {
  const isLoggedIn = store.getters["auth/isLoggedIn"]
  if (isLoggedIn) {
    return {
      name  : "reports",
      params: {
        locale : to.params.locale || "en-GB",
        channel: to.params.channel
      }
    }
  } else {
    const globalAskOrganisationCodeForWeb    = store.getters["auth/askOrganisationCodeForWeb"]
    const channels                           = store.getters["channels/channels"]
    const effectiveAskOrganisationCodeForWeb = channels[0]?.overrideAskOrganisationCodeForWeb ?
      channels[0].askOrganisationCodeForWeb : globalAskOrganisationCodeForWeb
    if (!effectiveAskOrganisationCodeForWeb) {
      return {
        name  : "reports",
        params: {
          locale : to.params.locale || "en-GB",
          channel: to.params.channel
        }
      }
    }
  }
}

const beforeReports = async (to, from) => {
  const isLoggedIn = store.getters["auth/isLoggedIn"]
  let report       = store.getters["auth/report"]
  if (!isLoggedIn) {
    if (!report) {
      const organisationCode = store.getters["channels/organisationCode"]
      if ((from.name === "organisation-code" && organisationCode) || (from.name === "languages" && !organisationCode) || (from.name === "consent" && !organisationCode)) {
        const channels         = store.getters["channels/channels"]
        const reporterLanguage = store.getters["auth/reporterLanguage"]
        const consentGiven     = store.getters["reports/isConsentGiven"]
        await store.dispatch("reports/addReport", {
          languageId: reporterLanguage.id,
          channelId : channels[0].id,
          source    : "web",
          consentGiven
        })
        report = store.getters["reports/reports"][0]
        store.commit("auth/setReport", report)
      }
    }
  }
  if (report) {
    return {
      name  : "report",
      params: {
        locale  : to.params.locale || "en-GB",
        channel : to.params.channel,
        reportId: report.id
      }
    }
  } else {
    return {
      name  : "home",
      params: {
        locale : to.params.locale || "en-GB",
        channel: to.params.channel
      }
    }
  }
}

const beforeReport = async to => {
  const isLoggedIn = store.getters["auth/isLoggedIn"]
  const report     = store.getters["auth/report"]
  if (isLoggedIn) {
    if (report.id !== +to.params.reportId) {
      return {
        name  : "report",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    } else {
      return {
        name  : "intake",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    }
  } else {
    if (report?.id !== +to.params.reportId) {
      return {
        name  : "logout",
        params: {
          locale : to.params.locale || "en-GB",
          channel: to.params.channel
        }
      }
    }
  }
}

const beforeIntake = async to => {
  const report = store.getters["auth/report"]
  if (report.id !== +to.params.reportId) {
    return {
      name  : "intake",
      params: {
        reportId: report.id,
        locale  : to.params.locale || "en-GB",
        channel : to.params.channel
      }
    }
  } else {
    if (report.status === "draft") {
      const channel = store.getters["channels/channels"][0]

      await store.dispatch("channels/loadFormTemplates", channel.id)
      const formTemplateId = store.getters["channels/formTemplatesOfChannels"][channel.id]

      await store.dispatch("formTemplates/loadFormTemplates", { id: formTemplateId, reportForm: true })
      const  formTemplates = store.getters["formTemplates/formTemplates"]

      if (!formTemplates?.length) {
        return {
          name  : "messages",
          params: {
            reportId: report.id,
            locale  : to.params.locale || "en-GB",
            channel : to.params.channel
          }
        }
      } else {
        await store.dispatch("formInstances/loadFormInstances", { reportId: report.id })
        const formInstances = store.getters["formInstances/formInstances"]
        let hasToLoadFormInstancesAgain

        for (const formTemplate of formTemplates) {
          if (!formInstances.find(formInstance => formInstance.formTemplateId)) {
            hasToLoadFormInstancesAgain = true
            await store.dispatch("formInstances/addFormInstance", {
              formTemplateId: formTemplate.id,
              reportId      : report.id
            })
          }
        }
        if (hasToLoadFormInstancesAgain) {
          await store.dispatch("formInstances/loadFormInstances", { reportId: report.id })
        }
      }
    } else {
      return {
        name  : "messages",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    }
  }
}

const beforeMessage = async to => {
  const report = store.getters["auth/report"]
  if (report.status === "draft") {
    await store.dispatch("messages/loadMessages")
    const messages = store.getters["messages/messages"]
    const message  = messages.find(message => message.id === +to.params.messageId)
    if (!message) {
      return {
        name  : "messages",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    } else {
      store.dispatch("messageItems/loadMessageItems", {
        messageId: message.id
      })
    }
  } else  {
    return {
      name  : "messages",
      params: {
        reportId: report.id,
        locale  : to.params.locale || "en-GB",
        channel : to.params.channel
      }
    }
  }
}

const beforeMessages = async (to, from) => {
  const report = store.getters["auth/report"]
  if (report) {
    if (!to.query.selectedMessage || !from.name) {
      await store.dispatch("messages/loadMessages")
      let messages = store.getters["messages/messages"]
      if (report.status === "draft") {
        if (!messages.length) {
          await store.dispatch("messages/addMessage", {
            reportId: report.id
          })
          messages = store.getters["messages/messages"]
        }
        return {
          name  : "message",
          params: {
            messageId: messages[0].id,
            reportId : report.id,
            locale   : to.params.locale || "en-GB",
            channel  : to.params.channel
          }
        }
      } else {
        const messageIds = messages.map(message => message.id)
        if (messageIds.length) {
          store.dispatch("messageItems/loadMessageItems", {
            messageId: messageIds.toString()
          })
        }
      }
    }
  } else {
    return {
      name  : "logout",
      params: {
        locale : to.params.locale || "en-GB",
        channel: to.params.channel
      }
    }
  }
}

const beforeAcknowledgement = async (to, from) => {
  const isLoggedIn = store.getters["auth/isLoggedIn"]
  const report     = store.getters["auth/report"]
  if (isLoggedIn) {
    if (report.status !== "new") {
      return {
        name  : "messages",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    }
    if(from.name === null) {
      return {
        name  : "messages",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    }
  } else {
    if (report.id !== +to.params.reportId) {
      return {
        name  : "logout",
        params: {
          locale : to.params.locale || "en-GB",
          channel: to.params.channel
        }
      }
    }
  }
}

const beforeVerifyEmail = async to => {
  const isLoggedIn                  = store.getters["auth/isLoggedIn"]
  const isSubscribedForNotification = store.getters["auth/isSubscribedForNotification"]
  const report                      = store.getters["auth/report"]
  if (isLoggedIn) {
    if (report.status !== "new") {
      return {
        name  : "messages",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    }
    if (!isSubscribedForNotification) {
      return {
        name  : "acknowledgement",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    }
  } else {
    if (report.id !== +to.params.reportId) {
      return {
        name  : "logout",
        params: {
          locale : to.params.locale || "en-GB",
          channel: to.params.channel
        }
      }
    }
  }
}

const beforeEmailVerified = async to => {
  const isLoggedIn      = store.getters["auth/isLoggedIn"]
  const isEmailVerified = store.getters["auth/isEmailVerified"]
  const report          = store.getters["auth/report"]
  if (isLoggedIn) {
    if (report.status !== "new") {
      return {
        name  : "messages",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    }
    if (!isEmailVerified) {
      return {
        name  : "verify-email",
        params: {
          reportId: report.id,
          locale  : to.params.locale || "en-GB",
          channel : to.params.channel
        }
      }
    }
  } else {
    if (report.id !== +to.params.reportId) {
      return {
        name  : "logout",
        params: {
          locale : to.params.locale || "en-GB",
          channel: to.params.channel
        }
      }
    }
  }
}

/**
  * This method will be called before languages page.
  * @param {*} to route to be navigated to.
  * @param {*} from route from where navigation was triggered.
  * @param {*} next method to change navigation.
  */
const beforeLanguages = async (to, from) => {
  if (!from.name === "home") {
    return {
      name  : "home",
      params: {
        locale : to.params.locale || "en-GB",
        channel: to.params.channel
      }
    }
  }
}

const beforeConsent = async to => {
  const isLoggedIn = store.getters["auth/isLoggedIn"]
  if (isLoggedIn) {
    return {
      name  : "reports",
      params: {
        locale : to.params.locale || "en-GB",
        channel: to.params.channel
      }
    }
  }
}